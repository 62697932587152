import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Layout,
  Row,
  Form,
  Input,
  Button,
  message,
  Spin,
  Space,
  Select,
  Upload,
  Divider,
  Result,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import useWindowSize from '@iso/lib/hooks/useWindowSize';
import appActions from '@iso/redux/app/actions';
import ThemeSwitcher from '@iso/containers/ThemeSwitcher/ThemeSwitcher';
import siteConfig from '@iso/config/site.config';
import Topbar from '@iso/containers/Topbar/Topbar';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import {
  FormContainer,
  FormGlobalStyles,
  styles,
  ColForm,
} from '@iso/pages/styles/Form.styles';
import MessageContent from '@iso/containers/Message/Message.styles';
import Box from '@iso/components/utility/box';
import api from '@iso/services/api';
import IntlMessages from '@iso/components/utility/intlMessages';
import FormularioHeader from '@iso/components/Formulario/FormularioHeader';
import { InboxOutlined } from '@ant-design/icons';

const { toggleAll } = appActions;
const { Option } = Select;
const { Dragger } = Upload;

const EmpresaFormAdmin = () => {
  const dispatch = useDispatch();
  const appHeight = useSelector((state) => state.App.height);
  const { width, height } = useWindowSize();
  const [form] = Form.useForm();
  const history = useHistory();
  const [fields, setFields] = useState([]);
  const [loadding, setLoadding] = useState(false);
  const [midiasForm, setMidiasForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState('');
  const [imgUploaded, setImgUploaded] = useState(false);

  const { id } = useParams();
  const { Content, Footer } = Layout;

  useEffect(() => {
    if (id) {
      api.get(`empresas/${id}`).then((response) => {
        setFields([
          { name: ['razao_social'], value: response.data.razao_social },
          { name: ['cnpj'], value: response.data.cnpj },
          { name: ['nome_fantasia'], value: response.data.nome_fantasia },
          { name: ['endereco'], value: response.data.endereco },
          { name: ['bairro'], value: response.data.bairro },
          { name: ['telefone'], value: response.data.telefone },
          { name: ['cep'], value: response.data.cep },
        ]);
      });
      api.get(`/midiassociais/empresa/${id}`).then((response) => {
        if (response.data.length !== 0) {
          setIsUpdate(response.data[response.data.length - 1].id);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);

  const handleSubmit = useCallback(
    async (data) => {
      setLoadding(true);
      try {
        const {
          razao_social,
          cnpj,
          nome_fantasia,
          telefone,
          endereco,
          bairro,
          cep,
          vendedor,
        } = data;
        if (id) {
          await api.put(`/empresas/${id}`, {
            razao_social,
            cnpj,
            nome_fantasia,
            telefone,
            endereco,
            bairro,
            cep,
            vendedor,
          });
        } else {
          await api.post('/empresas', {
            razao_social,
            cnpj,
            nome_fantasia,
            telefone,
            endereco,
            bairro,
            cep,
            vendedor,
          });
        }
        message.success(<MessageContent> Registro Salvo</MessageContent>, 1.5);
        setLoadding(false);
      } catch (err) {
        setLoadding(false);
        message.error(
          <MessageContent>
            Todos os campos devem estar preenchidos
          </MessageContent>,
          10,
        );
      }
      setMidiasForm(true);
    },
    [id],
  );

  const handleSubmiteMidiaForm = useCallback(
    async (data) => {
      const { facebook, instagram, twitter } = data;

      const midiaData = {
        facebook: facebook,
        instagram: instagram,
        twiter: twitter,
      };
      console.log(isUpdate);

      if (isUpdate) {
        api.put(`/midiassociais/${isUpdate}`, midiaData).then(() => {
          history.goBack();
        });
      } else {
        await api.post('/midiassociais', midiaData).then(() => {
          history.goBack();
        });
      }
    },
    [history],
  );

  const uploadEmpresa = useCallback(
    (info) => {
      console.log(info.file.originFileObj);

      const data = new FormData();
      data.append('avatar', info.file.originFileObj);

      try {
        api.patch(`/empresas/avatar/${id}`, data).then(setImgUploaded(true));
      } catch (error) {
        message.error('Erro ao carregar a imagem');
      }
    },
    [id],
  );

  return (
    <FormContainer>
      <FormGlobalStyles />
      <Layout>
        <Topbar />
        <Layout style={styles.layout}>
          <Layout className="isoContentMainLayout">
            <Content className="isomorphicContent">
              <LayoutWrapper>
                <Box className="animate__animated animate__fadeInUp">
                  <FormularioHeader
                    title={<IntlMessages id="sidebar.empresa" />}
                  />
                  {midiasForm ? (
                    //segunda parte do formulário
                    <>
                      <Form
                        ayout="vertical"
                        fields={fields}
                        form={form}
                        onFinish={handleSubmiteMidiaForm}
                        name="midiaForm"
                        scrollToFirstError
                      >
                        <ColForm>
                          <div className="col-second">
                            <Form.Item
                              name="empresa_avatar"
                              style={{ margin: '0 auto' }}
                            >
                              {imgUploaded ? (
                                <Result
                                  status="success"
                                  title="Imagem salva com sucesso!"
                                />
                              ) : (
                                <Dragger
                                  onChange={uploadEmpresa}
                                  style={{
                                    width: '100%',
                                    border: '1px dashed #bac8ff',
                                    padding: '16px',
                                  }}
                                >
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">
                                    Adicione uma imagem de sua empresa
                                  </p>
                                  <p
                                    className="ant-upload-hint"
                                    style={{ fontSize: '12px' }}
                                  >
                                    Arraste uma imagem ou clique para adicionar
                                  </p>
                                </Dragger>
                              )}
                            </Form.Item>

                            <Divider
                              style={{
                                marginTop: '40px',
                                fontSize: '1.125rem',
                                textAlign: 'center',
                              }}
                            >
                              Cadastre suas redes sociais
                            </Divider>
                            <label>
                              Facebook
                              <Form.Item name="facebook" className="input">
                                <Input placeholder="Coleque o link de sua página" />
                              </Form.Item>
                            </label>
                            <label>
                              Instagram
                              <Form.Item name="instagram" className="input">
                                <Input placeholder="Coleque o link de sua página" />
                              </Form.Item>
                            </label>
                            <label>
                              Twitter
                              <Form.Item name="twitter" className="input">
                                <Input placeholder="Coleque o link de sua página" />
                              </Form.Item>
                            </label>
                          </div>
                        </ColForm>

                        <Row justify="center" style={{ marginTop: 10 }}>
                          <Form.Item style={{ width: '100%' }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btnForm"
                            >
                              Salvar
                            </Button>
                          </Form.Item>
                        </Row>
                      </Form>
                    </>
                  ) : (
                    //primeira parte do formulário

                    <Form
                      layout="vertical"
                      fields={fields}
                      form={form}
                      name="register"
                      onFinish={handleSubmit}
                      scrollToFirstError
                    >
                      <ColForm>
                        <Form.Item
                          name="razao_social"
                          className="input"
                          label="Razão social:"
                          rules={[
                            {
                              required: true,
                              message: (
                                <IntlMessages id="antTable.razao.social.required" />
                              ),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="cnpj"
                          className="input"
                          label="Cnpj"
                          rules={[
                            { required: true, message: 'CNPJ é obrigatório' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="nome_fantasia"
                          className="input"
                          label="Nome fantasia"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="telefone"
                          className="input"
                          label="Tefefone"
                        >
                          <Input type="tel" />
                        </Form.Item>
                        <Form.Item
                          name="endereco"
                          className="input"
                          label="Endereço"
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="bairro"
                          className="input"
                          label="Bairro"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name="cep" className="input" label="Cep">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="vendedor"
                          className="input"
                          label="Meio de venda"
                        >
                          <Select>
                            <Option selected disabled value="">
                              Selecione um meio
                            </Option>
                            <Option value="internet">Internet</Option>
                            <Option value="vendedor">Vendedor</Option>
                          </Select>
                        </Form.Item>
                      </ColForm>
                      {loadding && (
                        <Space size="middle">
                          <Spin size="large" />
                        </Space>
                      )}
                      {!loadding && (
                        <Row
                          justify="center"
                          style={{
                            gap: 10,
                          }}
                        >
                          <Form.Item style={{ width: '100%' }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btnForm"
                            >
                              Próximo
                            </Button>
                          </Form.Item>
                        </Row>
                      )}
                    </Form>
                  )}
                </Box>
              </LayoutWrapper>
            </Content>
          </Layout>
        </Layout>
        <ThemeSwitcher />
      </Layout>
    </FormContainer>
  );
};

export default EmpresaFormAdmin;
