import React from 'react';
import { useDispatch } from 'react-redux';
import Popover from '@iso/components/uielements/popover';
import IntlMessages from '@iso/components/utility/intlMessages';
import userpic from '@iso/assets/images/user1.png';
import authAction from '@iso/redux/auth/actions';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import { Link } from 'react-router-dom';

const { logout } = authAction;

export default function TopbarUser({ isMargin }) {
  const [visible, setVisibility] = React.useState(false);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));

  const avatar_url = user && user.avatar_url ? user.avatar_url : userpic;
  const name = user && user.name ? user.name : '';

  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <Link to="/profile" className="isoDropdownLink">
        <IntlMessages id="topbar.profile" />
      </Link>

      <Link to="/changePassword" className="isoDropdownLink">
        <IntlMessages id="topbar.changepassword" />
      </Link>

      <div
        className="isoDropdownLink"
        style={{ cursor: 'pointer' }}
        onClick={() => dispatch(logout())}
      >
        <IntlMessages id="topbar.logout" />
      </div>
    </TopbarDropdownWrapper>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft"
    >
      <div className="isoImgWrapper" style={isMargin && { marginTop: '10px' }}>
        <img alt={name} src={avatar_url} />
        <span className="userActivity online" />
      </div>
    </Popover>
  );
}
