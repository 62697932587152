import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import EmpresaFormAdmin from './pages/Empresa/EmpresaFormAdmin';
import { isAuthenticated } from './hooks/auth';

const Dashboard = lazy(() => import('@iso/pages/Dashboard/Dashboard'));
const DashboardControle = lazy(() =>
  import('@iso/pages/DashboardControle/DashboardControle'),
);
const Calendario = lazy(() => import('@iso/pages/Calendario/Calendario'));
const Clientes = lazy(() => import('@iso/pages/Clientes/Clientes'));
const Agenda = lazy(() => import('@iso/pages/Agenda/Agenda'));
const Horarios = lazy(() => import('@iso/pages/Horarios/Horarios'));
const CadastroHoras = lazy(() =>
  import('@iso/pages/CadastroHoras/CadastroHoras'),
);

const User = lazy(() => import('@iso/pages/User/User'));
const UserForm = lazy(() => import('@iso/pages/User/UserForm'));
const ChangePassword = lazy(() => import('@iso/pages/User/ChangePassword'));
const Profile = lazy(() => import('@iso/pages/User/Profile'));

const Area = lazy(() => import('@iso/pages/Area/Area'));
const AreaForm = lazy(() => import('@iso/pages/Area/AreaForm'));
const Categoria = lazy(() => import('@iso/pages/Categoria/Categoria'));
const CategoriaForm = lazy(() => import('@iso/pages/Categoria/CategoriaForm'));
const Servico = lazy(() => import('@iso/pages/Servico/Servico'));
const ServicoForm = lazy(() => import('@iso/pages/Servico/ServicoForm'));
const Empresa = lazy(() => import('@iso/pages/Empresa/Empresa'));
const EmpresaForm = lazy(() => import('@iso/pages/Empresa/EmpresaForm'));
const Profissional = lazy(() => import('@iso/pages/Profissional/Profissional'));
const ProfissionalForm = lazy(() =>
  import('@iso/pages/Profissional/ProfissionalForm'),
);
const RegisterAdmin = lazy(() =>
  import('@iso/pages/RegisterAdmin/RegisterAdmin'),
);
const Cancelamento = lazy(() => import('./pages/Cancelamento/Cancelamento'));
const AgendaControle = lazy(() =>
  import('./pages/AgendaControle/AgendaControle'),
);

const publicRoutes = [
  {
    path: '/cancelamento/:empresa_id/:phone_id/:cliente_id',
    exact: true,
    component: Cancelamento,
  },
  {
    path: '/agenda/:empresa_id/:phone_id/:cliente_id',
    exact: true,
    component: Agenda,
  },
  {
    path: '/horarios/:href/:empresa_id/:phone_id/:cliente_id/:agendamento_id?',
    exact: true,
    component: Horarios,
  },
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('@iso/pages/ForgotPassword/ForgotPassword')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('@iso/pages/Agenda/Agenda')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('@iso/pages/ResetPassword/ResetPassword')),
  },
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = isAuthenticated();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default function Routes() {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRoute exact path="/cadastrohoras">
              <CadastroHoras />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/agendaControle">
              <AgendaControle />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboard">
              <Dashboard />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/calendario">
              <Calendario />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/clientes">
              <Clientes />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboardControle">
              <DashboardControle />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/area">
              <Area />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/areaForm">
              <AreaForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/areaForm/:id">
              <AreaForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/empresa">
              {user?.id === '0fec49ce-ba59-4da8-ac80-86fa2f40cff6' ? (
                <Empresa />
              ) : (
                <Redirect to={`/empresaFormAdmin/${user?.empresa_id}`} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/categoria">
              <Categoria />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/categoriaForm">
              <CategoriaForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/categoriaForm/:id">
              <CategoriaForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute exact path="/servico">
              <Servico />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/servicoForm">
              <ServicoForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/servicoForm/:id">
              <ServicoForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute exact path="/empresaForm">
              <EmpresaForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute exact path="/empresaForm/:id">
              <EmpresaForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute exact path="/empresaFormAdmin/:id">
              <EmpresaFormAdmin />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute exact path="/profissional">
              <Profissional />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/profissionalForm">
              <ProfissionalForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/profissionalForm/:id">
              <ProfissionalForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/registerAdmin/:empresa_id">
              <RegisterAdmin />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute exact path="/user">
              <User />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute exact path="/userForm">
              <UserForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
            <PrivateRoute path="/userForm/:id">
              <UserForm />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute path="/changepassword">
              <ChangePassword />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>

            <PrivateRoute path="/profile">
              <Profile />
              <WhatsAppWidget
                phoneNumber="5524999263347"
                companyName="Mit Tech"
                replyTimeText="Suporte técnico"
                message="Olá, como podemos te ajudar? 😀"
                inputPlaceHolder="Digite sua mensagem"
                sendButtonText="Enviar"
              />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
