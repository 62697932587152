import axios from "axios";
import { getToken} from "@iso/hooks/auth";

const api = axios.create({ baseURL: "https://api.dbragas.com:35"});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) { config.headers.Authorization = `Bearer ${token}`;}
  return config;
});

api.interceptors.response.use(response => {return response;}, 
  error => {
    if(window.location.pathname!=="/" && error.response.status === 401){
      localStorage.removeItem('id_token');
      localStorage.removeItem('user');
      window.location.replace("/");
    }
    return Promise.reject(error.response); 
  }
);

export default api;