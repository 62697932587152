import styled, { createGlobalStyle } from 'styled-components';
import { palette } from 'styled-theme';
import { shade } from 'polished';

export const ColForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 32px;
  justify-content: center;
  align-items: center;
  width: 75vw;
  margin: 0 auto;

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }

  .col-second {
    width: 75vw;
    margin-left: 30px;

    @media screen and (max-width: 820px) {
      margin-left: 0;
    }
  }

  .rowForm {
    display: flex;
    gap: 4rem;
  }

  .column-full {
    grid-column: 1/-1;
  }

  .input {
    width: 100%;
    margin-bottom: 10px;

    @media screen and (max-width: 820px) {
      margin-bottom: 5px;
    }
  }

  .ant-picker.ant-picker-borderless {
    border: 2px solid #bac8ff !important;
    background-color: #f7f7f7 !important;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const ColSecondForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 75vw;
  gap: 0 32px;
  justify-content: center;
  box-sizing: border-box;
`;

export const FormGlobalStyles = createGlobalStyle`
body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

fieldset {
  padding: 10px !important;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

.form-header {
  display:flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  h1 {
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.color[7]};
    white-space: nowrap;
  }
  hr {
    height: 1px;
    background-color: ${(props) => props.theme.palette.color[7]};
    width:100%;
    margin-left: 15px;
    border: none;
  }
  a{
    background-color: ${(props) => props.theme.palette.primary[14]};
    transition: 0.3s ease;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    border: 1px solid #BAC8FF;

    &:hover {
      transform: scale(1.1);
      color: #fff;
      transition: 0.3s ease;
    }
  }
}

.crud-container {
  background: transparent;
  padding-top: 0;
  border: none;

  .isoBoxWrapper {
    background: transparent;
    border: none;
    padding: 0;
  }

  h1 {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fda935;
  }

  hr {
    width: 100%;
    color: #fda935;
    margin: 0 15px;
  }

  .crud-title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    a {
      white-space: nowrap;
      padding: 7px 15px;
      background: #ffab36;
      color: #fff;
      border-radius: 5px;
    }
  }

  .ant-table-pagination {
    float: none !important;
    justify-content: center !important;
  }

  .ant-table-thead > tr > th {
    background: #fff9e2 !important;
    color: #343031 !important;
  }
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.hide-dropdown {
  display: none;
}
`;

export const FormContainer = styled.div`
  -webkit-overflow-scrolling: touch;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 2px solid #bac8ff;
      background: #f7f7f7;
      height: 3rem;
      box-shadow: none;
      border-radius: 6px;
      align-items: center;
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
      width: 100%;
    }
  }

  .btnForm {
    background-color: ${(props) => props.theme.palette.primary[14]};
    border: 2px solid #bac8ff;
    border-radius: 6px;
    width: 100%;
    height: 3rem;
    margin-top: 10px;

    &:hover {
      filter: brightness(1.5);
    }

    &.btn-second {
      background: #fff;
      color: ${(props) => props.theme.palette.primary[14]};
      margin-top: 0;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .isomorphicContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    box-sizing: border-box;
    height: 100vh;

    .form-full {
      width: 100%;
    }

    .date-input input {
      border: none !important;
      height: 2rem;
    }

    .isoLayoutContentWrapper {
      padding: 0;
    }

    input {
      border: 2px solid #bac8ff;
      height: 3rem;
      border-radius: 6px;
      box-shadow: none;
      background-color: #f7f7f7;

      &:focus {
        border: 2px solid #3b5998;
        background: #fff;
      }
    }
    input:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  .ant-select-selector {
    border: 2px solid #bac8ff;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.palette.color[7]} !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${(props) => props.theme.palette.color[7]};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${(props) => props.theme.palette.color[7]};
  }

  .ant-upload-select-picture-card {
    width: 10rem !important;
    height: 10rem !important;
  }

  .form-servico {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 32px;
    width: 100%;
    align-items: center;
    justify-content: center;

    .subcategoria {
      grid-column: 1/-1;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    @media screen and (max-width: 520px) {
      grid-template-columns: 1fr;

      .btn-servico {
        margin-top: 20px;
        margin-bottom: -10px;
      }
    }
  }

  .input-reset {
    .ant-input {
      border: none;

      &:focus {
        border: none;
        background: none;
      }
    }
  }
`;

export const AvatarInput = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
    position: relative;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    top: 250px;
    cursor: pointer;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${shade(0.2, '#0F1B45')};

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }

    &:hover {
      filter: brightness(1.5);
      transition: 0.3s ease;
    }
  }
`;

export const styles = {
  layout: {
    flexDirection: 'row',
    overflowX: 'hidden',
  },
  content: {
    padding: '80px 0px',
    background: '#f1f3f6',
    boxSizing: 'border-box',
    width: '100%',
  },
  input: {
    backgroundColor: '#f7f7f7',
    height: '3rem',
    borderRadius: '6px',
    border: '2px solid #bac8ff',
  },
  button: {
    backgroundColor: '#3b5998',
    borderColor: '#3b5998',
  },
};
