import React from 'react';
import { SidebarBlankIcon } from '@iso/config/icon.config';
import { ImProfile } from 'react-icons/im';
import { FaRegBuilding } from 'react-icons/fa';
import {
  AiOutlineOrderedList,
  AiOutlineInsertRowBelow,
  AiOutlineBook,
  AiOutlineSchedule,
} from 'react-icons/ai';
import { BsCalendar3Range } from 'react-icons/bs';
import { IoBarChartSharp } from 'react-icons/io5';
import { BiTime } from 'react-icons/bi';
import { IoIosPerson } from 'react-icons/io';

const options = [
  {
    key: 'empresa',
    label: 'Empresa',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <FaRegBuilding size="7em" />,
    iconSmall: <FaRegBuilding />,
    text: 'Acesse as informações da sua empresa',
  },
  {
    key: 'categoria',
    label: 'Categoria',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <AiOutlineOrderedList size="7em" />,
    iconSmall: <AiOutlineOrderedList />,
    text: 'Gerencie as categorias disponíveis',
  },
  {
    key: 'area',
    label: 'Subcategoria',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <AiOutlineInsertRowBelow size="7em" />,
    iconSmall: <AiOutlineInsertRowBelow />,
    text: 'Gerencie as subcategorias disponíveis',
  },
  {
    key: 'servico',
    label: 'Serviço',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <AiOutlineBook size="7em" />,
    iconSmall: <AiOutlineBook />,
    text: 'Controle os serviços oferecidos',
  },
  {
    key: 'profissional',
    label: 'Profissional',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <ImProfile size="7em" />,
    iconSmall: <ImProfile />,
    text: 'Gerencie os seus profissionais',
  },
  {
    key: 'cadastroHoras',
    label: 'Horário',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <BiTime size="7em" />,
    iconSmall: <BiTime />,
    text: 'Defina os horários de funcionamento',
  },
  {
    key: 'calendario',
    label: 'Calendário',
    leftIcon: <BsCalendar3Range size={19} />,
    icon: <BsCalendar3Range size="7em" />,
    iconSmall: <BsCalendar3Range />,
    text: 'Acesse os agendamentos realizados',
  },
  {
    key: 'agendaControle',
    label: 'Agenda',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <AiOutlineSchedule size="7em" />,
    iconSmall: <AiOutlineSchedule />,
    text: 'Realize novos agendamentos',
  },
  {
    key: 'dashboardControle',
    label: 'Dashboard',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <IoBarChartSharp size="7em" />,
    iconSmall: <IoBarChartSharp />,
    text: 'Visualize o gráfico mensal de agendamentos',
  },
  {
    key: 'clientes',
    label: 'Clientes',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <IoIosPerson size="7em" />,
    iconSmall: <IoIosPerson />,
    text: 'Gerencie os seus clientes',
  },
];

const optionsBasic = [
  {
    key: 'empresa',
    label: 'Empresa',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <FaRegBuilding size="7em" />,
    iconSmall: <FaRegBuilding />,
  },
  {
    key: 'servico',
    label: 'Serviço',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <AiOutlineBook size="7em" />,
    iconSmall: <AiOutlineBook />,
  },
  {
    key: 'profissional',
    label: 'Loja',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <ImProfile size="7em" />,
    iconSmall: <ImProfile />,
  },
  {
    key: 'cadastroHoras',
    label: 'Horário',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <BiTime size="7em" />,
    iconSmall: <BiTime />,
  },
  {
    key: 'agendaControle',
    label: 'Agenda',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <AiOutlineSchedule size="7em" />,
    iconSmall: <AiOutlineSchedule />,
  },
  {
    key: 'dashboardControle',
    label: 'Dashboard',
    leftIcon: <SidebarBlankIcon size={19} />,
    icon: <IoBarChartSharp size="7em" />,
    iconSmall: <IoBarChartSharp />,
  },
];

export default options;
export { optionsBasic };
