import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Tooltip } from 'antd';
import TopbarUser from './TopbarUser';
import TopbarWrapper from './Topbar.styles';
import TopbarMenu from './TopbarMenu';
import { Link } from 'react-router-dom';
import IntlMessages from '@iso/components/utility/intlMessages';
import mitLogo from '@iso/../../public/images/logo.png';
import { ImHome } from 'react-icons/im';

const { Header } = Layout;

export default function Topbar({ isMargin }) {
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme,
  );
  const { collapsed, openDrawer } = useSelector((state) => state.App);

  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
  };
  const href = window.location.href;

  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={`${
          isCollapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
        } animate__animated animate__fadeInDown`}
      >
        <div className="header-container">
          <div className="isoLeft">
            <Link to="/dashboard">
              <Tooltip
                placement="bottom"
                title={<IntlMessages id="sidebar.painel" />}
              >
                <ImHome className="icon-home" />
              </Tooltip>
            </Link>

            {href === 'http://localhost:3000/dashboard' ||
            'http://localhost:3000/agendaControle' ||
            'https://agenda.mit-tech.com.br/dashboard' ||
            'https://agenda.mit-tech.com.br/agendaControle' ? (
              ''
            ) : (
              <div className="logo-separator"></div>
            )}
            {href === 'http://localhost:3000/dashboard' ||
            'http://localhost:3000/agendaControle' ||
            'https://agenda.mit-tech.com.br/dashboard' ||
            'https://agenda.mit-tech.com.br/agendaControle' ? (
              ''
            ) : (
              <TopbarMenu />
            )}
          </div>

          <img
            alt="Logo Mit Tech"
            src={mitLogo}
            style={{ height: '4rem' }}
            className="logo"
          />

          <ul className="isoRight">
            <li onClick={() => {}} className="isoUser">
              <TopbarUser isMargin={isMargin}/>
            </li>
            <li onClick={() => {}} className="isoUser"></li>
          </ul>
        </div>
      </Header>
    </TopbarWrapper>
  );
}
