import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Link } from 'react-router-dom';
import options from '../Sidebar/options';
import { MenuOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

export default function TopbarMenu() {
 
  const content = (
    <Menu>
     { options && options.map(option=>{
       if(option.submenu){
        return (
          <SubMenu title={<IntlMessages id={option.label} />}>
            { option.submenu && option.submenu.map(submenuoption=>{
              return (
              <Menu.Item>
                <Link to={`/${submenuoption.key}`} className="menulink">
                  <IntlMessages id={submenuoption.label} />
                </Link>
                </Menu.Item>            
              )
            })}            
          </SubMenu>
        )
       }else{
        return (
          <Menu.Item>
            <Link to={`/${option.key}`} className="menulink">
              <IntlMessages id={option.label} />
            </Link>
          </Menu.Item>
        )
       }
      
      })
    }
    
    </Menu>
);

  return (
    <Dropdown overlay={content} trigger={['click']}>
      <Button>
        <MenuOutlined />
      </Button>
    </Dropdown>
    
  );
}
