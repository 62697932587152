import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0; 
    box-sizing: border-box;
  }

  body {
    background: #F0F2F5;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }
  

  fieldset {
    border:1px solid #ccc !important;
    border-radius:8px;
    padding: 0 15px !important;

    legend {
      width:auto !important;
      font-size:1rem !important;
      margin: 0 8px 0 2px !important;
      padding: 0 8px 0 2px !important;
      border: 0 !important;
      color: #595959 !important;
    }
  }

  .form-exec-tarefa {
    h4 {
      margin: 20px 0 3px;
    }
    .ant-row.ant-form-item {
      margin: 15px 0;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #ffb300;
      border-color: #ffb300;
    }    
  }
  .ant-modal-header {   
    background: #f4f4f4 !important;    
  }

  .ant-btn.ant-btn-circle.ant-btn-icon-only.button-onlyicon {
    padding: 0;
    background: none;
    border: none;

    span {
      font-size: 20px;
      color: #787878;
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .ant-picker, .ant-input-number {
    width:100% !important;
  }
  .ant-row {
    margin:15px 0;

    .ant-row {
      margin:0;
    }
  }
  .ant-row .ant-btn {
    width:100%
  }
  .ant-form-vertical .ant-form-item-label {
    padding-bottom:3px
  }

  .button-plus-aleft {    
    background: #ff9f1a;
    border: 0;
    width: 30px;
    padding: 5px 7px 5px;
    border-radius: 6px;
    color: #fff;
  }

  .button-text-primary {
    color: #ff9f1a;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-content {
    background-color: #f4f4f4 !important;
    .ant-input-disabled, .ant-picker-disabled{
      color: rgba(0, 0, 0, 0.55) !important;
      background-color: #e6e6e6 !important;
    }
    .ant-select-disabled {
      .ant-select-selector{
        color: rgba(0, 0, 0, 0.55) !important;
        background-color: #e6e6e6 !important;
      }
    }
    .ant-upload {
      .anticon-plus-circle {
        font-size: 20px;
        margin-right: 5px;
        svg {
          fill: #f79456;
        }
      }
    }
    .ant-form-item-label, label {
      font-weight: 500;
      color: #3a3a3a;
    }
    .modal-checklist {
      .ant-form-item-control {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
      }
    }
    .ant-picker-input {
      flex-direction: row-reverse;
    }
    .ant-picker-suffix {
      color: rgb(255, 144, 74) !important;
      font-size: 18px;
      margin-right: 10px;
    }
    .ant-picker.ant-picker-disabled {
      padding: 0 !important;
      background: transparent !important;
      border: none !important;
    }
    .ant-picker-input > input[disabled] {
      background: #e6e6e6 !important;
      border: 1px solid #ccc !important;
      padding: 3px 10px !important;
    }
  }
  .ant-modal-body {
    padding-top: 0 !important;
  }
  .ant-modal-footer {
    text-align:center !important;
  }

  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${
    '' /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

`;

export default GlobalStyles;
