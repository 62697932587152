
export default function profissionalIdReducer(state, action) {
    switch (action.type) {
        case 'SELECT_PROFISSIONAL_AGENDA':
            return {
                ...state,
                profissionalId: action.payload
            }
        case 'SELECT_SERVICO_AGENDA':
            return {
                ...state,
                servicoId: action.payload
            }
        default:
            return {
                profissionalId: ""
            }
    }
}
