import React from 'react';
import { FormGlobalStyles } from '@iso/pages/styles/Form.styles';

export default class FormularioHeader extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <>
        <FormGlobalStyles />
        <div className="form-header">
          <h1>{this.props.title}</h1>
          <hr></hr>
          {this.props.children}
        </div>
      </>
    );
  }
}
