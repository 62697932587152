import { combineReducers } from 'redux'
import App from '@iso/redux/app/reducer'
import Auth from '@iso/redux/auth/reducer'
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer'
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer'
import profissionalIdReducer from '@iso/redux/profissionalId/reducer'

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  profissionalIdReducer
})
